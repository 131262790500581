/* jshint ignore:start */

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        // JavaScript to be fired on all pages
        $(document).ready(function () {
          $('.nav-primary--container').show();
          adjustWidth();
      });
        //Debouncer
        function debounce(func, wait, immediate) {
            var timeout;
            return function() {
                var context = this, args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) {func.apply(context, args);}
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) {func.apply(context, args);}
            };
        }

        //URL Parameter - ?stadt=ludwigshafen
        var getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1];
                }
            }
        };







       function adjustWidth() {
         var parentwidth = $(".container-fluid").width() + 30;
         $(".fixed").width(parentwidth);
       }

        //hovereffect for cities
        $('.citylink').hover(function() {
          var city = $(this).attr('href');
          city = city.substring(1);
          $('.mapcity.' + city).addClass('hover');
        },function() {
          var city = $(this).attr('href');
          city = city.substring(1);
          $('.mapcity.' + city).removeClass('hover');
        });

        //extend_button
        $(document).on('click', '.button-extend', function (event) {
          var toExtend = $(this).attr('to-extend');
          $('#' + toExtend).toggleClass('city-content-hidable-shown');
          if ($(this).text() === 'weniger') {
            $(this).text('mehr lesen');
          }
          else {
            $(this).text('weniger');
          }
        });
        //menu mobile click fix
        $(document).on('click', '#menu-item-1888, #menu-item-3643', function (event) {
          var link = $(this).find("a").attr('href');
          console.log(link);
          window.location.href = link;
        });

       //Sticky Container Hover Underline
        $('.nav-primary-fixed #menu-hcv-primary-menu > li').hover(function() {
          $( this ).siblings().addClass('border-bottom');
          $(this).find('.sub-menu').addClass('hover-active');
          $( this ).addClass('hover-active');
        }, function() {
          $( this ).siblings().removeClass('border-bottom');
            $(this).find('.sub-menu').removeClass('hover-active');
            $( this ).removeClass('hover-active');
        });

        //Mobile Menu - open and close
        $(document).find('.burger-icon').click(function(){
          if($('.menu-hcv-primary-menu-container').hasClass('mobile-active')) {
            $('.menu-hcv-primary-menu-container').removeClass('mobile-active');
            $('.close-mobile').hide();
            $('.open-mobile').show();
            $('.burger-label').html('Menü');
          } else {
            $('.menu-hcv-primary-menu-container').addClass('mobile-active');
            $('.open-mobile').hide();
            $('.close-mobile').show();
            $('.burger-label').html('Menü schliessen');
          }
        });

        //Mobile Menu - Submenu
        if ($(window).width() <= 768) {

          $('.nav-primary-fixed .primary-fixed > li > a').click(function(e){
            e.preventDefault();
          });

          $('.nav-primary-fixed .primary-fixed > li').click(function(e){
            $('.nav-primary-fixed .primary-fixed > li > ul').removeClass('hover-active');
            $('.nav-primary-fixed .primary-fixed > li').removeClass('mobilemenuactive');
            $(this).addClass('mobilemenuactive');
            $(this).children('ul.sub-menu').addClass('hover-active');
          });

        }

        //Silo Links
        function asilo_link(href) {
          var href;
          window.open(href, '_self');
        }

        $(document).on('click', '.asilo_link', function (event) {
          asilo_link($(this).attr('data-href'));
        });

        //Border-Container Same Size
        $(document).find('.border-container').parents('.wpb_wrapper, .vc_row').css('height', '100%'); //TODO!

        //Smooth Scroll
        smoothScroll.init({
          selector: '[data-scroll]',
          offset: 100,
        });

        function isScrolledIntoView(el) {
          var elemTop = el.getBoundingClientRect().top;
          var elemBottom = el.getBoundingClientRect().bottom;

          var isVisible = elementTop < window.innerHeight && elementBottom >= 0;
          return isVisible;
        }
    

        //Resize
        $(window).on("resize", debounce( function () {
            adjustWidth();
        },100));
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        //ANIMATION
        var getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1];
                }
            }
        }

        var $animation = getUrlParameter('animation');
        if (typeof $animation !== 'undefined') {
          if ($animation == "true"){
            $screenwidth = $( document ).width();
            $('body').css('overflow', 'hidden');

            $('body').keyup(function(e){
               if(e.keyCode == 13){
                e.preventDefault();

                $('.counter').each(function() {
                  var $this = $(this),
                      countTo = $this.attr('data-count');

                  $({ countNum: $this.text()}).animate({
                    countNum: countTo
                  },

                  {
                    duration: 4000,
                    easing:'linear',
                    step: function() {
                      $this.text(Math.floor(this.countNum));
                      $('.animation-progress').css('height', this.countNum+'%');
                    },
                    complete: function() {
                      $this.text(this.countNum);
                      //alert('finished');
                      $('.animation-progress').hide();
                      $('.whiteanimationlayer').css("width", $screenwidth+'px');
                      $('.whiteanimationlayer').animate({
                          left: '0%',
                      }, 1500, function() {
                          $('.greenanimationlayer').hide();
                          $('.whiteanimationlayer').animate({
                            left: '100%',
                          }, 1500, function(){
                            $('.animation-container').remove();
                            $('body').css('overflow', 'inherit');
                          } );
                      });
                    }

                  });
                });

                return false;
               }
            });
          }
        }
      }
    },
    // impressionen page
    'impressionen': {
      init: function() {
      },
      finalize: function() {

        var $container = $(".masonry-grid");

        $container.imagesLoaded(function () {
          var $grid = $('.masonry-grid').masonry({
            percentPosition: true,
            columnWidth: '.grid-sizer',
            itemSelector: '.grid-item'
          });
          $('.masonry-grid').css("visibility", "visible");
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };



  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.
