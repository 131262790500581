(function($) {

    var domain_name = '<?php global $domain_name; echo $domain_name; ?>';

    $('a[href*="http"]').not('[href*="' + window.location.hostname + '"]').not('[href*="xmachina"], [href*="localhost"], [href*="abbvie-care.de"], [href*="hcvversorgungplus.de"], [href*="abbvie-plus"], [href*="www.abbvie."], [href*="mailto"]').on('click', function (e) {
        e.preventDefault();

        $(this).addClass('back_focus');

        if ($(this).attr('href').indexOf(domain_name) < 0) {

            $('.overlay_container.exit_dis .overlay .link_a').attr('href', $(this).attr('href'));
            $('.overlay_container.exit_dis .overlay .link_span').html($(this).attr('href'));
            $('.overlay_container.exit_dis').fadeIn();
            $('.overlay_container.exit_dis .overlay').addClass('opened');
            return false;

        }
    });

    $(document).on("click", ".link_a", function () {
        $('.overlay_container').fadeOut('fast');
        window.open($(this).attr('href'), '_blank');
    });

    $(document).on('click', '.close_overlay, .x, .link_a', function () {
        $('.overlay_container').fadeOut('fast');
        $('.overlay').removeClass('opened');
        return false;
    });



})(jQuery); // Fully reference jQuery after this point.